  <!-- email -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent :inline="true">
        <el-form-item label class="nja-form-line">
          <el-input
            v-model.trim="search.subject"
            placeholder="标题"
            clearable
            @keyup.enter.native="searchHandle"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
          <el-button type="primary" @click="addShow" plain>发邮件</el-button>
          <el-button type="primary" @click="addPersonShow" plain>发个人邮件</el-button>
        </el-form-item>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="sendTime" label="发送时间" align="center" width="180"></el-table-column>
        <el-table-column prop="world" label="收件人" align="center" width="180"></el-table-column>
        <el-table-column prop="subject" label="标题" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="content" label="内容" align="center" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column label="操作" fixed="right" align="center" width="80px">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button size="mini" type="danger" @click="deletHandle(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>-->
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      title="发送邮件"
      :visible.sync="dialogBatchVisible"
      :close-on-click-modal="false"
      width="700px"
    >
      <el-form ref="form" class="formlayout" :model="form" label-width="90px" :show-message="false" label-position="left">
        <el-form-item class="dccform-line" label="发送人" prop="subject">
          <el-col :span="12">
            <el-input v-model="form.username" clearable placeholder="请输入发送人"></el-input>
          </el-col>
          <el-col :span="10" style="margin-left: 20px;">
            <el-input v-model="form.password" clearable placeholder="密码"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item class="dccform-line" v-if="!form.isWorld" label="收件人" prop="world" required>
          <el-select
            v-model="form.world"
            multiple
            filterable
            style="width: 100%;"
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="(item, i) in tableUserData"
              :key="i"
              :label="`${item.userName}`"
              :value="item.userId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dccform-line" v-else label="收件人" prop="world"  required>
          <el-select
            v-model="form.world"
            style="width: 100%;"
            multiple
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in worlds"
              :key="item.world"
              :label="`${item.world}区`"
              :value="item.world"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dccform-line" label="标题" prop="subject" required>
          <el-input v-model="form.subject" clearable placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item class="dccform-line" label="邮件内容" prop="content" required>
          <el-input v-model="form.content" type="textarea" :rows="5" placeholder="请输入邮件内容"></el-input>
          <div style="text-align:left;">
            <el-button size="mini" @click="handleColor">颜色</el-button>
            <el-button size="mini" @click="handleSize">字体大小</el-button>
            <el-button size="mini" @click="handleLink">超连接</el-button>
          </div>
        </el-form-item>

        <el-form-item class="dccform-line" label="发送间隔个数"  v-if="form.isWorld"  label-width="150px">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-input v-model.number="form.number" type="number" clearable placeholder="个">
               <template slot="append">个</template>
            </el-input>
          </el-col>
          <el-col :span="2">/</el-col>
            <el-col :span="10">
            <el-input v-model.number="form.time" type="number" clearable placeholder="秒">
                  <template slot="append">秒</template>
            </el-input>
          </el-col>
        </el-row>
        </el-form-item>

        <el-form-item class="dccform-line"></el-form-item>
        <el-form-item label-width="0px">
          <el-button type="primary" @click="saveHandle">发送</el-button>
          <el-button @click="dialogBatchVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  EmailSelectPage,
  WorldSelectPage,
  UserSelectPage,
  SendEmail,
  SendPersonEmail
} from '@/api'
export default {
  data() {
    return {
      dialogBatchVisible: false,
      dialogBuyVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        subject: '',
        content: ''
      },
      searchUser: {
        current: 1,
        size: 100,
        total: 0,
        userId: '',
        userName: ''
      },
      form: {
        username: '',
        password: '',
        world: '',
        subject: '',
        content: '',
        number: 10,
        time: 60
      },
      loading: false,
      worlds: [],
      tableUserData: [],
      tableData: []
    }
  },
  methods: {
    handleColor() {
      this.form.content += '<color="red">字体内容</color>'
    },
    handleSize() {
      this.form.content += '<size="30">字体内容</size>'
    },
    handleLink() {
      this.form.content += '<a src="#">超连接</a>'
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.loadUserData(query)
      } else {
        this.tableUserData = []
      }
    },
    addPersonShow() {
      this.form = {
         username: '',
        password: '',
        isWorld: false,
        world: '',
        subject: '',
        content: '',
        number: 10,
        time: 60
      }
      this.dialogBatchVisible = true
    },
    addShow() {
      this.form = {
         username: '',
        password: '',
        isWorld: true,
        world: '',
        subject: '',
        content: '',
        number: 10,
        time: 60
      }
      this.dialogBatchVisible = true
    },
    saveHandle() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 保存
          let params = Object.assign({}, this.form)
          params.world = params.world.join(',')
          let API = SendPersonEmail
          if (this.form.isWorld) {
            API = SendEmail
          }
          API(params).then((res) => {
            console.log('SendEmail', res)
            if (res.code === 20000) {
              this.$message({
                type: 'success',
                message: '操作成功'
              })
              this.dialogBatchVisible = false
              this.loadData()
            }
          })
        }
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    // deletHandle(row) {
    //   deleteVersion({ id: row.id }).then((res) => {
    //     console.log('deleteVersion', res)
    //     if (res.code === 20000) {
    //       this.$message({
    //         type: 'success',
    //         message: '操作成功'
    //       })
    //       this.loadData()
    //     }
    //   })
    // },
    loadDict() {
      WorldSelectPage({
        current: 1,
        size: 100
      }).then((res) => {
        console.log('WorldSelectPage:', res)
        this.worlds = res.data.records.filter((d) => d.status == 1)
      })
    },
    loadUserData(name) {
      let params = Object.assign({}, this.searchUser)
      params.userName = name
      UserSelectPage(params)
        .then((res) => {
          this.loading = false
          console.log('UserSelectPage:', res)
          this.tableUserData = res.data.records
          this.searchUser.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    loadData() {
      EmailSelectPage(this.search)
        .then((res) => {
          console.log('EmailSelectPage:', res)
          this.tableData = res.data.records
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
    this.loadDict()
    this.loadUserData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
</style>
