  <!-- 版本管理 -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.code"
                placeholder="版本号"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="addShow" plain>发布版本</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="code" label="版本号" align="center" width="180"></el-table-column>
        <el-table-column prop="releaseTime" label="发布时间" align="center" width="180"></el-table-column>
        <el-table-column prop="content" label="更新内容" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="80px">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button size="mini" type="danger" @click="deletHandle(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      title="发布版本"
      :visible.sync="dialogBatchVisible"
      :close-on-click-modal="false"
      width="700px"
    >
      <el-form ref="form" class="formlayout" :model="form" label-width="90px" label-position="left">
        <el-form-item class="dccform-line" label="版本号" prop="code">
          <el-input v-model="form.code" placeholder="请输入版本号"></el-input>
        </el-form-item>
        <el-form-item class="dccform-line" label="更新内容" prop="content">
          <el-input v-model="form.content" type="textarea" :rows="5" placeholder="请输入更新内容"></el-input>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-button type="primary" @click="saveHandle">保存</el-button>
          <el-button @click="dialogBatchVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { SelectVersionPage, deleteVersion, addVersion } from '@/api'
export default {
  data() {
    return {
      dialogBatchVisible: false,
      dialogBuyVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        username: '',
        nickname: ''
      },
      form: {},
      buyUrl: '',
      tableData: []
    }
  },
  methods: {
    addShow () {
      this.form = {}
      this.dialogBatchVisible = true
      // getVersion({code: '1.0.5'}).then(res => {
      //   console.log('getVersion', res)
      // })
    },
    saveHandle() {
      // 保存
      let params = Object.assign({}, this.form)
      addVersion(params).then((res) => {
        console.log('addVersion', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.dialogBatchVisible = false
          this.loadData()
        }
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    deletHandle(row) {
      deleteVersion({ id: row.id }).then((res) => {
        console.log('deleteVersion', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.loadData()
        }
      })
    },
    loadData() {
      SelectVersionPage(this.search)
        .then((res) => {
          console.log('success:', res)
          this.tableData = res.data.records
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
</style>
