  <!-- 统计查询 -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.bindingId"
                placeholder="游戏ID"
                clearable
                @keyup.enter.native="loadData"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label class="nja-form-line">
              <el-date-picker
                v-model="search.searchDate"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item style="margin-left: 60px;">
              <el-button type="primary" @click="loadData" icon="el-icon-search">查 询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <!-- <nja-chart class="chart" id="day7handleChart" :data="day7Data"></nja-chart> -->
      <el-table :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="bindingId" label="游戏ID" align="center"></el-table-column>
        <el-table-column prop="api" label="类型" align="center"></el-table-column>
        <el-table-column prop="size" label="操作次数" align="center"></el-table-column>
        <el-table-column prop="result" label="成功次数" align="center"></el-table-column>
      </el-table>
    </el-main>
  </div>
</template>

<script>
import { BuyQuery } from '@/api'
// import NjaChart from '@/components/Chart'
import { drawLine } from './echarts'
export default {
  // components: { NjaChart },
  data() {
    return {
      dialogBatchVisible: false,
      dialogBuyVisible: false,
      search: {
        bindingId: '',
        searchDate: ''
      },
      form: {},
      buyUrl: '',
      tableData: [],
      day7Data: {},
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        }
    }
  },
  methods: {
    drawMap() {

      this.day7Data = drawLine(
        [
          {
            type: '任务1',
            name: 'one',
            color: 'rgb(62,145,247)'
          },
          {
            type: '任务2',
            name: 'two',
            color: 'rgb(97,190,110)'
          }
        ],
        [
          {
            type: '周一',
            one: 1,
            two: 2
          },
          {
            type: '周二',
            one: 5,
            two: 6
          },
          {
            type: '周三',
            one: 15,
            two: 12
          },
          {
            type: '周四',
            one: 25,
            two: 13
          },
          {
            type: '周五',
            one: 17,
            two: 6
          },
          {
            type: '周六',
            one: 13,
            two: 6
          },
          {
            type: '周日',
            one: 7,
            two: 3
          }
        ]
      )
    },
    loadData() {
      BuyQuery({
        bindingId: this.search.bindingId,
        startDate: (this.search.searchDate && this.search.searchDate.length == 2) ? (this.search.searchDate[0] + ' 00:00:00') : '',
        endDate: (this.search.searchDate && this.search.searchDate.length == 2) ? (this.search.searchDate[1] + ' 23:59:59') : '',
      })
        .then((res) => {
          console.log('success:', res)
          this.tableData = res.data
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
    this.drawMap()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
.chart {
  width: 100%;
  height: 300px;
}
</style>
