  <!-- key -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent  :inline="true">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.key"
                placeholder="KEY"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="addKey" plain>新增KEY</el-button>
            </el-form-item>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table size="mini" :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="key" label="KEY" align="center" width="320"></el-table-column>
        <el-table-column prop="days" label="有效期天" align="center" width="100"></el-table-column>
        <el-table-column prop="createTime" label="生成时间" align="center" width="100"></el-table-column>
        <el-table-column prop="type" label="完整版" align="center" width="80" v-if="$store.getters.role !== 'proxy'">
         <template slot="header">
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">
              完整版本/限制版本<br />限制版本每天限制查询300次<br />完整版本查询没限制，有尺码管理
            </div>   
              <div>
                    <i class="el-icon-info"></i>完整版
                  </div> 
            </el-tooltip>
         
          </template>
          <template slot-scope="scope">
              <el-switch
              v-model="scope.row.type"
              :disabled="scope.row.diffday == 0 "
              :active-value="1"
              :inactive-value="0"
              @change="changeKeyType(scope.row.id)"
              active-color="#13ce66"
              inactive-color="#999"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="firstLoginTime" label="首次登陆时间" align="center" width="140"></el-table-column>
        <el-table-column prop="lastLoginTime" label="最后登陆时间" align="center" width="140"></el-table-column>
        <el-table-column prop="activateTime" label="激活时间" align="center" width="100"></el-table-column>
        <el-table-column prop="expireTime" label="有效期至" align="center" width="100"></el-table-column>

        <el-table-column prop="status" fixed="right" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status === 0">未绑定</el-tag>
            <el-tag type="info" v-if="scope.row.status === 1">已绑定</el-tag>
            <el-tag type="warning" v-if="scope.row.status === -1">小黑屋</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="150px">
          <template slot-scope="scope">
            <div style="display: flex;">
            <el-button
              size="mini"
              type="warning"
              v-if="scope.row.status !== -1"
              @click="deletekey(scope.row, true)"
            >小黑屋</el-button>
             <el-button
              size="mini"
              type="warning"
              v-if="scope.row.status === -1"
              @click="deletekey(scope.row, false)"
            >释放</el-button>
            <!-- <el-button
              size="mini"
              type="warning"
              @click="deletekey(scope.row)"
            >加时</el-button> -->

            <el-button size="mini" type="danger" v-if="$store.getters.role !== 'proxy'" @click="deleteHandle(scope.row)">删除</el-button>
              </div>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      title="新增KEY"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form class="body" :model="form" ref="addKeyForm">
        <el-form-item prop="days" :role="[{message: '数量必填', required: true, trigger: 'change'}]">
          <el-input
            class="input"
            clearable
            :min="1"
            :max="9999"
            v-model.number="form.days"
            placeholder="请输入KEY有效时间"
          >
            <template slot="prepend">有效期</template>
            <template slot="append">天</template>
          </el-input>
        </el-form-item>
        <el-button
          class="btn primaryBtn"
          type="warning"
          @click="addKeyHandle"
          :loading="addKeyLoading"
        >生成KEY</el-button>
        <div class="btns"></div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="绑定Discord"
      :visible.sync="dialogBindVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form class="body" :model="form" ref="addKeyForm">
        <el-form-item prop="token" :role="[{message: '必填', required: true, trigger: 'change'}]">
          <el-input class="input" type="textarea" clearable v-model="form.token" placeholder="请输入Discord的Token">
            <template slot="prepend">TOKEN</template>
          </el-input>
        </el-form-item>
        <el-button
          class="btn primaryBtn"
          type="warning"
          @click="setToken"
          :loading="addKeyLoading"
        >绑定TOKEN</el-button>
        <div class="btns"></div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { SelectKeyPage, addKey, updateKey, getSetting, setSetting, UpdateKeyType, deleteKey } from '@/api'
export default {
  data() {
    return {
      search: {
        current: 1,
        size: 20,
        total: 0,
        key: '',
        username: '',
        startDate: '',
        endDate: ''
      },
      form: {
        days: 20,
        token: ''
      },
      status: {
        1: '已绑定',
        0: '未绑定',
        '-1': '小黑屋'
      },
      options: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '未绑定',
          value: '0'
        },
        {
          label: '已绑定',
          value: '1'
        }
      ],
      dialogTableVisible: false,
      dialogBindVisible: false,
      addKeyLoading: false,
      tableData: []
    }
  },
  methods: {

    deleteHandle(row) {
      this.$confirm(`删除此条Key, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteKey({
          key: row.key
        }).then((res) => {
          console.log('deleteKey', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    changeKeyType (id) {
       UpdateKeyType({ id }).then((res) => {
        console.log('UpdateKeyType', res)
      })
    },
    getToken () {
      getSetting({code: 'TOKEN'}).then(res => {
         console.log('getSetting', res);
        this.form.token = res.data
      })
    },
    setToken () {
        // 新增KEY入库
      this.$refs['addKeyForm'].validate((valid) => {
        this.addKeyLoading = true
        console.log(valid)
        if (valid) {
           setSetting({code: 'TOKEN', value: this.form.token}).then(res => {
            console.log('setSetting', res);
             this.addKeyLoading = false
              this.$message({
                type: 'success',
                message: '操作成功'
              })
              this.loadData()
              this.dialogBindVisible = false
          })
        }
      })
      
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    addKey() {
      this.dialogTableVisible = true
    },
    loadData() {
      SelectKeyPage(this.search)
        .then((res) => {
          console.log('success:', res)
          this.tableData = res.data.records
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    handleCurrentPage(page) {
      this.checkCurrent = page
    },
    deletekey(row, flag) {
      //
      if (flag) {
        row.status = -1
      } else {
        if (row.bindingDevice) {
          row.status = 1
        } else {
          row.status = 0
        }
      }
      updateKey(row).then((res) => {
        console.log('deletekey', res)
        if (res.code === 20000) {
          this.loadData()
        }
      })
    },
    addKeyHandle() {
      // 新增KEY入库
      this.$refs['addKeyForm'].validate((valid) => {
        this.addKeyLoading = true
        console.log(valid)
        if (valid) {
          addKey({ days: this.form.days }).then((res) => {
            console.log('addKey', res)
             if (res.code === 20000) {
               this.addKeyLoading = false
               this.$message({
                 type: 'success',
                 message: '操作成功'
               })
               this.loadData()
               this.dialogTableVisible = false
             }
          })
        }
      })
    }
  },
  mounted() {
    this.loadData()
    this.getToken()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.body {
  ::v-deep {
    .el-input__inner {
      height: 60px;
    }
  }
}
.input2 {
  width: 60%;
}
.btn2 {
  height: 60px;
  width: calc(40% - 10px);
}
.btn {
  width: 100%;
  height: 60px;
}
</style>
