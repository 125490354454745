
/**
 * 画线
 */
export const drawLine = (types, data) => {
  return {
    legend: {
      bottom: 0,
      icon: 'line',
      itemWidth: 10,
      data: types.map(d => d.type)
    },
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '10%',
      top: '5%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: data.map(d => d.type)
    },
    yAxis: {
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: '#eee'
        }
      },
      type: 'value'
    },
    series: types.map(d => {
      return {
        name: d.type,
        type: 'line',
        stack: '总量',
        smooth: true,
        color: d.color,
        data: data.map(j => j[d.name])
      }
    })
  }
}
