  <!-- 用户管理 -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.username"
                placeholder="手机号"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.nickname"
                placeholder="昵称"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="dialogBatchVisible = true" plain>新建用户</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="username" label="用户名称" align="center" width="180"></el-table-column>
        <el-table-column prop="nickname" label="昵称" align="center"></el-table-column>
        <el-table-column prop="role" label="角色" align="center"></el-table-column>
        <el-table-column prop="number" label="剩余授权次数" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :disabled="scope.row.role === 'admin'"
              :active-value="0"
              :inactive-value="-1"
              @change="changeUserStatus(scope.row.id)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="280px">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button
                size="mini"
                type="warning"
                v-if="scope.row.username !== 'admin'"
                @click="addAuth(scope.row)"
              >添加授权</el-button>
              <el-button
                size="mini"
                type="warning"
                v-if="scope.row.username !== 'admin'"
                @click="updatePwd(scope.row)"
              >重置密码</el-button>
              <el-button
                size="mini"
                type="info"
                @click="showBuyBtn(scope.row)"
              >购买设置</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      title="新增用户"
      :visible.sync="dialogBatchVisible"
      :close-on-click-modal="false"
      width="700px"
    >
      <el-form ref="form" class="formlayout" :model="form" label-width="90px" label-position="left">
        <el-form-item class="dccform-line" label="用户名称" prop="username">
          <el-input v-model="form.username" placeholder="请输入用户名称"></el-input>
        </el-form-item>
         <el-form-item class="dccform-line" label="用户昵称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item class="dccform-line" label="登录密码" prop="password">
          <el-input v-model="form.password" placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item class="dccform-line" label="角色" prop="role">
          <el-select v-model="form.role" style="width: 100%;">
            <el-option label="游戏代理无限1天" value="proxy"></el-option>
            <el-option label="user" value="user"></el-option>
            <el-option label="socks5" value="socks5"></el-option>
            <el-option label="币安" value="币安"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-button type="primary" @click="saveHandle">保存</el-button>
          <el-button @click="dialogBatchVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
     <el-dialog
      title="购买按钮地址配置"
      :visible.sync="dialogBuyVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form class="body">
        <el-form-item>
          <el-input class="input" type="textarea" clearable v-model="buyUrl" placeholder="请输入地址">
            <template slot="prepend">购买按钮地址</template>
          </el-input>
        </el-form-item>
        <el-button
          class="btn primaryBtn"
          type="warning"
          @click="saveBuyUrl"
        >确认</el-button>
        <div class="btns"></div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  SelectUserPage,
  UpdateUserStatus,
  RegisterRequest,
  addAuthTimes,
  RegisterUpdateUser,
  getSetting,
  setSetting
} from '@/api'
import { md5 } from '@/util/crypto'
export default {
  data() {
    return {
      dialogBatchVisible: false,
      dialogBuyVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        username: '',
        nickname: ''
      },
      form: {},
      buyUrl: '',
      tableData: []
    }
  },
  methods: {
    showBuyBtn (row) {
      // 显示购买按钮地址
      this.actived = row
      this.dialogBuyVisible = true
      getSetting({
        code: row.id
      }).then(res => {
        console.log('getSetting', res)
        if (res.code === 20000) {
          this.buyUrl = res.data
        }
      })
    },
    saveBuyUrl () {
      setSetting({code: this.actived.id, value: this.buyUrl}).then(res => {
        console.log('setSetting', res)
        if (res.code === 20000) {
           this.dialogBuyVisible = false
           this.$message({
            type: 'success',
            message: '操作成功'
          })
        }
      })
    },
    saveHandle() {
      // 保存
      let params = Object.assign({}, this.form)
      params.password = md5(params.password)
      RegisterRequest(params).then((res) => {
        console.log('RegisterRequest', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.dialogBatchVisible = false
          this.loadData()
        }
      })
    },
    addAuth(row) {
      this.$prompt('请输入添加授权次数', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\+?[1-9][0-9]*$/,
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        // 添加授权次数
        addAuthTimes({
          id: row.id,
          times: value
        }).then((res) => {
          console.log('addAuthTimes', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    updatePwd(row) {
      this.$prompt('请输入新密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
         inputPattern: /\S/,
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        // 修改密码
        RegisterUpdateUser({
          username: row.username,
          password: md5(value)
        }).then((res) => {
          console.log('RegisterUpdateUser', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    changeUserStatus(id) {
      UpdateUserStatus({ id }).then((res) => {
        console.log('UpdateUserStatus', res)
      })
    },
    loadData() {
      SelectUserPage(this.search)
        .then((res) => {
          console.log('success:', res)
          this.tableData = res.data.records
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
</style>
