  <!-- key -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent  :inline="true">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.bindingId"
                placeholder="游戏ID、激活码、首次游戏ID"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="addAuth" plain>新增授权</el-button>
                <el-button
                type="info"
                @click="showBuyBtn"
              >购买地址设置</el-button>
            </el-form-item>
            <div class="right" v-if="$store.getters.role == 'user'" >当前剩余授权次数：{{$store.getters.number}}</div>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table size="mini" :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="expireTime" label="有效期至" align="center" width="100"></el-table-column>
        <el-table-column prop="days" label="有效期天" align="center" width="70"></el-table-column>
        <el-table-column prop="diffday" label="剩余天数" align="center" width="70">
          <template slot-scope="scope">
            <span :style="{color: scope.row.diffday < 5 ? 'red': '#606266'}">{{scope.row.diffday}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bindingId" label="绑定游戏ID" align="center" width="150"></el-table-column>
        <el-table-column prop="bindingName" label="绑定游戏名" align="center" width="150"></el-table-column>
        <el-table-column prop="bindingWorldId" label="游戏区" align="center" width="60"></el-table-column>
        <el-table-column prop="bindingVersion" label="版本" align="center" width="60"></el-table-column>
        <el-table-column prop="type" label="完整版" align="center" width="60" v-if="$store.getters.role !== 'proxy'">
          <template slot-scope="scope">
              <el-switch
              v-model="scope.row.type"
              :disabled="scope.row.diffday == 0 "
              :active-value="1"
              :inactive-value="0"
              @change="changeKeyType(scope.row.id)"
              active-color="#13ce66"
              inactive-color="#999"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="lastLoginTime" label="最近登陆时间" align="center" width="140"></el-table-column>
        <el-table-column prop="key" label="KEY" align="center" width="320"></el-table-column>
        <el-table-column prop="issuer" label="授权人" align="center" width="100"></el-table-column>
        <el-table-column prop="createTime" label="生成时间" align="center" width="100"></el-table-column>
        <el-table-column prop="firstLoginTime" label="首次登陆时间" align="center" width="140"></el-table-column>
         <el-table-column prop="firstBindingId" label="首次绑定游戏ID" align="center" width="140"></el-table-column>
         <el-table-column prop="firstBindingName" label="首次绑定游戏名称" align="center" width="140"></el-table-column>
        <el-table-column prop="activateTime" label="激活时间" align="center" width="100"></el-table-column>

        <el-table-column prop="status" fixed="right" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status === 0">未绑定</el-tag>
            <el-tag type="info" v-if="scope.row.status === 1">已绑定</el-tag>
            <el-tag type="warning" v-if="scope.row.status === -1">小黑屋</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" :width="$store.getters.role === 'proxy' ? '150px' : '300px'" align="center">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button
                size="mini"
                type="warning"
                v-if="scope.row.status !== -1"
                @click="updateStatus(scope.row, true)"
              >小黑屋</el-button>
              <el-button
                size="mini"
                type="warning"
                v-if="scope.row.status === -1"
                @click="updateStatus(scope.row, false)"
              >释放</el-button>
              <el-button size="mini" type="warning" v-if="$store.getters.role !== 'proxy'" @click="extendAuth(scope.row)">延长授权</el-button>
              <el-button
                size="mini"
                type="warning"
                v-if="!scope.row.bindingId"
                @click="binding(scope.row)"
              >绑定</el-button>
              <el-button size="mini" type="warning" v-else-if="$store.getters.role !== 'proxy'" @click="unbinding(scope.row)">解绑</el-button>
              <el-button size="mini" type="warning" v-if="$store.getters.role !== 'proxy'" @click="deleteHandle(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
     <el-dialog
      title="购买按钮地址配置"
      :visible.sync="dialogBuyVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form class="body">
        <el-form-item>
          <el-input class="input" type="textarea" clearable v-model="buyUrl" placeholder="请输入地址">
            <template slot="prepend">购买按钮地址</template>
          </el-input>
        </el-form-item>
        <el-button
          class="btn primaryBtn"
          type="warning"
          @click="saveBuyUrl"
        >确认</el-button>
        <div class="btns"></div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  SelectKeyPage,
  updateStatus,
  binding,
  extendAuth,
  unbinding,
  addAuth,
  deleteKey,
  getSetting,
  setSetting,
  UpdateKeyType
} from '@/api'
import {loadUserNumber} from '@/router'
import moment from 'moment'
export default {
  data() {
    return {
      search: {
        current: 1,
        size: 20,
        total: 0,
        key: '',
        bindingId: '',
        startDate: '',
        endDate: ''
      },
      form: {
        days: 20,
        token: ''
      },
      buyUrl: '',
      status: {
        1: '已绑定',
        0: '未绑定',
        '-1': '小黑屋'
      },
      options: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '未绑定',
          value: '0'
        },
        {
          label: '已绑定',
          value: '1'
        }
      ],
      dialogBuyVisible: false,
      tableData: []
    }
  },
  methods: {
    changeKeyType (id) {
       UpdateKeyType({ id }).then((res) => {
        console.log('UpdateKeyType', res)
      })
    },
     showBuyBtn (row) {
      // 显示购买按钮地址
      this.actived = row
      this.dialogBuyVisible = true
      getSetting({
        code: sessionStorage.getItem('userid')
      }).then(res => {
        console.log('getSetting', res)
        if (res.code === 20000) {
          this.buyUrl = res.data
        }
      })
    },
    saveBuyUrl () {
      setSetting({code: sessionStorage.getItem('userid'), value: this.buyUrl}).then(res => {
        console.log('setSetting', res)
        if (res.code === 20000) {
           this.dialogBuyVisible = false
           this.$message({
            type: 'success',
            message: '操作成功'
          })
        }
      })
    },
    binding(row) {
      this.$prompt('请输入游戏ID', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        binding({
          bindingId: value,
          key: row.key
        }).then((res) => {
          console.log('binding', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    addAuth() {
      this.$confirm(`确定添加授权吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        addAuth({}).then((res) => {
          console.log('addAuth', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    extendAuth(row) {
      this.$confirm(`确定增加30天授权吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        extendAuth({
          key: row.key
        }).then((res) => {
          console.log('extendAuth', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    deleteHandle(row) {
      this.$confirm(`删除此条授权, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteKey({
          key: row.key
        }).then((res) => {
          console.log('deleteKey', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
     unbinding(row) {
      this.$confirm(`确定解除${row.bindingId}使用吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        unbinding({
          key: row.key
        }).then((res) => {
          console.log('unbinding', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    loadData() {
      loadUserNumber()
      SelectKeyPage(this.search)
        .then((res) => {
          console.log('success:', res)
          let now = moment();
          this.tableData = res.data.records.map(d => {
            if (d.expireTime) {
              //获取需要对比的时间
              let m2 = moment(d.expireTime);
              if (m2 > now) {
                //计算相差多少天 day可以是second minute
                d.diffday = m2.diff(now, 'day')
              } else {
                d.diffday = 0
              }
            } else {
              d.diffday = '/'
            }
            return d
          })
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    handleCurrentPage(page) {
      this.checkCurrent = page
    },
    updateStatus(row, flag) {
      //
      if (flag) {
        row.status = -1
      } else {
        if (row.bindingId) {
          row.status = 1
        } else {
          row.status = 0
        }
      }
      updateStatus({
        key: row.key,
        status: row.status
      }).then((res) => {
        console.log('updateStatus', res)
        if (res.code === 20000) {
          this.loadData()
        }
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.body {
  ::v-deep {
    .el-input__inner {
      height: 60px;
    }
  }
}
.input2 {
  width: 60%;
}
.btn2 {
  height: 60px;
  width: calc(40% - 10px);
}
.btn {
  width: 100%;
  height: 60px;
}
.right {
  font-size: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
