  <!-- 币安管理 -->
<template>
  <div class="m-t-20">
    <el-container>
      <el-aside width="400px">
        <div class="left-aside">
          <div class="title">
            <i class="el-icon-setting"></i>我的账号
          </div>
          <div>
            <el-descriptions class="margin-top" :column="2" border>
              <template slot="extra">
                <el-button type="primary" size="small" @click="editToken = true">配置</el-button>
              </template>
              <el-descriptions-item label="APIKey" :span="2">{{binanceToken.apiKey ? '已配置' : '未配置'}}</el-descriptions-item>
              <el-descriptions-item label="secretKey" :span="2">{{binanceToken.secretKey ? '已配置' : '未配置'}}</el-descriptions-item>
              <template v-if="assetInfo && assetInfo.length > 0">
                <el-descriptions-item
                  :span="2"
                  :label="item.asset"
                  v-for="(item, i) in assetInfo"
                  :key="i"
                >{{item.free}}</el-descriptions-item>
              </template>
              <el-descriptions-item label="钱包信息" v-else>无</el-descriptions-item>
            </el-descriptions>
          </div>
          <el-form
            ref="form0"
            class="leftform"
            :model="form"
            label-width="0px"
            label-position="top"
            v-if="editToken"
          >
            <el-form-item label="APIKey" prop="apiKey">
              <el-input
                v-model="binanceToken.apiKey"
                type="textarea"
                :rows="2"
                placeholder="请输入APIKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="SecretKey" prop="secretKey">
              <el-input
                v-model="binanceToken.secretKey"
                type="textarea"
                :rows="2"
                placeholder="请输入SecretKey"
              ></el-input>
            </el-form-item>

            <el-form-item label-width="0px" style="margin-top: 30px;">
              <el-button type="primary" style="width: 100%;" @click="saveToken">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-aside>
      <el-main class="padding">
        <header class="main-body-search">
          <el-form :model="search" @submit.native.prevent  :inline="true">
                <el-form-item label class="nja-form-line" name="symbol">
                  <el-input
                    v-model.trim="search.symbol"
                    placeholder="币名称"
                    clearable
                    @keyup.enter.native="searchHandle"
                  ></el-input>
                </el-form-item>
             
                <el-form-item>
                  <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
                  <el-button type="primary" @click="add" :disabled="!binanceToken.apiKey">下 单</el-button>
                </el-form-item>
          </el-form>
        </header>
        <el-main class="main-body-table" style="height:auto;">
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            height="calc(100vh - 350px)"
            @expand-change="load"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table
                  :data="scope.row.expdata"
                  size="mini"
                  v-loading="!scope.row.load"
                  stripe
                  style="width: 100%"
                >
                  <el-table-column prop="side" label="买/卖"></el-table-column>
                  <el-table-column prop="orderId" label="订单ID"></el-table-column>
                  <el-table-column prop="sumQuantity" label="累计成交数量"></el-table-column>
                  <el-table-column prop="sumPrice" label="累计成交金额"></el-table-column>
                  <el-table-column prop="eventTime" label="更新时间"></el-table-column>
                  <el-table-column prop="status" label="状态"></el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="symbol" label="币名称" align="center" width="180"></el-table-column>
            <el-table-column prop="startTime" label="下单时间" align="center" min-width="160px">
              <template slot-scope="scope">
                <div>{{scope.row.startTime}}</div>
                <div>{{scope.row.endTime}}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="endTime" label="结束下单时间" align="center"  min-width="160px"></el-table-column> -->
            <el-table-column prop="side" label="买/卖" align="center"></el-table-column>
            <el-table-column prop="quantity" label="计划" align="center">
              <template slot-scope="scope">
                <div>数量：{{scope.row.quantity}}</div>
                <div>价格：{{scope.row.price}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  v-if="scope.row.status == 5"
                  effect="dark"
                  :content="scope.row.errMsg"
                  placement="bottom-end"
                >
                  <span>{{statusData[scope.row.status]}}</span>
                </el-tooltip>
                <span v-else>{{statusData[scope.row.status]}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="200px">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  style="margin: 2px;"
                  type="success"
                  v-if="scope.row.status == 0"
                  @click="runnow(scope.row)"
                >立刻下单</el-button>
                <el-button
                  size="mini"
                  style="margin: 2px;"
                  type="warning"
                  v-if="scope.row.status != 1 && scope.row.status != 2"
                  @click="editRow(scope.row)"
                >编辑</el-button>
                <el-button
                  size="mini"
                  style="margin: 2px;"
                  type="danger"
                  v-if="scope.row.status == 1"
                  @click="cancleHandle(scope.row)"
                >取消</el-button>
                <el-button
                  size="mini"
                  style="margin: 2px;"
                  type="danger"
                  v-if="scope.row.status != 1 && scope.row.status != 2"
                  @click="deleteHandle(scope.row)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!--工具条-->
          <el-pagination
            class="main-body-table-page"
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            :current-page="search.current"
            :page-size="search.size"
            :total="search.total"
          ></el-pagination>
        </el-main>
      </el-main>
    </el-container>
    <el-dialog
      class="orderClass"
      title
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="600px"
    >
      <template #title>
        <div class="title">
          <i class="el-icon-setting"></i> 下单
        </div>
      </template>
      <div class="left-aside" style="height: auto;">
        <el-form ref="form" class="leftform" :model="form" label-width="0px" label-position="top">
          <el-form-item>
            <el-radio-group v-model="form.side"  :disabled="form.id">
              <el-radio label="BUY">买</el-radio>
              <el-radio label="SELL">卖</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="币类型" prop="symbol">
                <el-input v-model="form.symbol" placeholder="请输入币类型"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提前时间(毫秒)" prop="beforeTime">
                <el-input v-model="form.beforeTime" type="number" placeholder="请输入提前时间/毫秒"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="开始下单时间" prop="startTime">
                  <el-date-picker
                    v-model="form.startTime"
                    style="width: 100%"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :clearable="false"
                    placeholder="选择开始下单时间"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="订单取消时间" prop="endTime">
                  <el-date-picker
                    v-model="form.endTime"
                    style="width: 100%"
                    type="datetime"
                    :clearable="false"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择订单取消时间"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item :label="`${form.side == 'BUY' ? '买入' : '卖出'}数量`" prop="quantity">
                  <el-input v-model="form.quantity" type="number" placeholder="请输入数量"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="`${form.side == 'BUY' ? '买入' : '卖出'}价格`" prop="price">
                  <el-input v-model="form.price" type="number" placeholder="请输入价格"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item label-width="0px" style="margin-top: 30px;">
            <el-button type="primary" style="width: 100%;" @click="saveHandle">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectConfigPage,
  saveConfig,
  runnow,
  deleteConfig,
  cancelConfig,
  getOrder,
  binanceInfo,
  saveToken
} from '@/api'
export default {
  data() {
    return {
      search: {
        current: 1,
        size: 20,
        total: 0,
        symbol: '',
        orderId: ''
      },
      editToken: false,
      dialogVisible: false,
      form: {
        side: 'BUY',
        symbol: '',
        beforeTime: '',
        endTime: '',
        afterBuyTime: '',
        quantity: '',
        sellQuantity: '',
        price: '',
        sellPrice: '',
        status: ''
      },
      statusData: {
        0: '未开始',
        1: '进行中',
        2: '已完成',
        3: '取消',
        5: '失败'
      },
      tableData: [],
      assetInfo: [],
      binanceToken: {
        apiKey: '',
        secretKey: ''
      }
    }
  },
  methods: {
    loadInfo() {
      binanceInfo().then((res) => {
        console.log('binanceInfo', res)
        if (res.code === 20000) {
          this.assetInfo = res.data.data.filter(d => d.free > 0)
          this.binanceToken = res.data.token || {
            apiKey: '',
            secretKey: ''
          }
        }
      })
    },
    load(row, state) {
      console.log('load====', row, state)
      if (!row.load) {
        // 展开加载
        getOrder({
          configId: row.id
        }).then((res) => {
          console.log('getOrder', res)
          if (res.code === 20000) {
            let data = res.data
            row.expdata = [row.side].map((d) => {
              let obj = data.find((j) => j.side == d)
              if (!obj) {
                obj = {
                  side: d,
                  orderId: '-',
                  sumQuantity: '',
                  sumPrice: '',
                  eventTime: '',
                  status: '未下单'
                }
              }
              return obj
            })
            row.load = true
          }
        })
      }
    },
    add() {
      this.form = {
        side: 'BUY',
        symbol: '',
        beforeTime: '',
        endTime: '',
        afterBuyTime: '',
        quantity: '',
        sellQuantity: '',
        price: '',
        sellPrice: '',
        status: ''
      }
      this.dialogVisible = true
    },
    editRow(row) {
      this.form = Object.assign({}, row)
      this.dialogVisible = true
    },
    deleteHandle(row) {
      deleteConfig({
        id: row.id
      }).then((res) => {
        console.log('deleteConfig', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.loadData()
        }
      })
    },
    cancleHandle(row) {
      cancelConfig({
        id: row.id
      }).then((res) => {
        console.log('cancelConfig', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '取消成功'
          })
          this.loadData()
        }
      })
    },
    runnow(row) {
      runnow({
        id: row.id
      }).then((res) => {
        console.log('runnow', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '下单成功'
          })
          this.loadData()
        }
      })
    },
    saveHandle() {
      // 保存
      let params = Object.assign({}, this.form)
      delete params.lastUpdateTime
      saveConfig(params).then((res) => {
        console.log('saveConfig', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          this.dialogVisible = false
          this.loadData()
        }
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    saveToken() {
      if (!(this.binanceToken.secretKey && this.binanceToken.apiKey)) {
        this.$message({
          type: 'warning',
          message: '参数必填'
        })
        return
      }
      saveToken(this.binanceToken).then((res) => {
        console.log('saveToken', res)
        if (res.code === 20000) {
          this.editToken = false
          this.$message({
            type: 'success',
            message: '保存成功'
          })
        }
      })
    },
    loadData() {
      selectConfigPage(this.search)
        .then((res) => {
          console.log('selectConfigPage:', res)
          this.tableData = res.data.records.map((d) => {
            d.load = false
            return d
          })
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadInfo()
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
.title {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}
.left-aside {
  border: 1px solid #e3e8ee;
  height: calc(100vh - 190px);
  background-color: #fff;
  color: #333;
  padding: 15px;
  overflow: auto;
  position: relative;

  .leftform {
    padding: 10px;
  }
  .addrow {
    position: absolute;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }
  .buystyle {
    // background-color: #bbb;
    padding: 2px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid green;
  }
  ::v-deep {
    .el-form-item {
      margin-bottom: 0px;
    }
    .el-input__prefix {
      display: none;
    }
    .el-input--prefix .el-input__inner {
      padding-left: 15px;
      padding-right: 10px;
    }
    .el-form--label-top .el-form-item__label {
      padding: 0px;
      text-align: left;
      color: #333;
    }
  }
}
.padding {
  padding: 0px;
  margin-left: 10px;
  height: calc(100vh - 170px);
}
.orderClass {
  ::v-deep .el-dialog__body {
    padding: 0px;
  }
}
</style>
