  <!-- 区管理 -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent :inline="true">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.world"
                placeholder="区号"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="addShow" plain>新增区</el-button>
            </el-form-item>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="world" label="区号" align="center"></el-table-column>
        <el-table-column prop="level" label="等级+" align="center"></el-table-column>
        <el-table-column prop="peopleNumber" label="人数" align="center"></el-table-column>
         <el-table-column prop="updateTime" label="查询时间" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">{{status[scope.row.status]}}</template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="150px">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button size="mini" type="danger" @click="deletHandle(scope.row)">删除</el-button>

                <el-button size="mini" type="success" @click="refresh(scope.row)">刷新</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      title="新增区"
      :visible.sync="dialogBatchVisible"
      :close-on-click-modal="false"
      width="700px"
    >
      <el-form ref="form" class="formlayout" :model="form" label-width="90px" label-position="left">
        <el-form-item class="dccform-line" label="区号" prop="world">
          <el-input v-model="form.world" placeholder="请输入区号"></el-input>
        </el-form-item>
       <el-form-item class="dccform-line" label="等级+" prop="level">
         <el-input-number v-model="form.level" style="width: 100%;" :min="1" :max="99" label="请输入等级"></el-input-number>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-button type="primary" @click="saveHandle">新增</el-button>
          <el-button @click="dialogBatchVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { WorldSelectPage, DeleteWorld, InsertWorld, RefreshUser } from '@/api'
export default {
  data() {
    return {
      dialogBatchVisible: false,
      dialogBuyVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        world: ''
      },
      status: {
        0: '未查询',
        1: '已查询',
        2: '查询中'
      },
      form: {
        world: '',
        level: 25
      },
      buyUrl: '',
      tableData: []
    }
  },
  methods: {
    addShow () {
      this.form = {
         world: '',
        level: 25
      }
      this.dialogBatchVisible = true
      // getVersion({code: '1.0.5'}).then(res => {
      //   console.log('getVersion', res)
      // })
    },
    saveHandle() {
      // 保存
      let params = Object.assign({}, this.form)
      InsertWorld(params).then((res) => {
        console.log('InsertWorld', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.dialogBatchVisible = false
          this.loadData()
        }
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    refresh(row) {
      RefreshUser({
        world: row.world
      }).then(res => {
        console.log('RefreshUser', res);
      })
    },
    deletHandle(row) {
      DeleteWorld({ id: row.id }).then((res) => {
        console.log('DeleteWorld', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.loadData()
        }
      })
    },
    loadData() {
      WorldSelectPage(this.search)
        .then((res) => {
          console.log('WorldSelectPage:', res)
          this.tableData = res.data.records
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}

</style>
