  <!-- key -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="10" type="flex">
          <el-form-item label class="nja-form-line">
            <el-input
              style="max-width: 200px;margin-right: 20px;"
              v-model.trim="search.serverport"
              placeholder="端口"
              clearable
              @keyup.enter.native="searchHandle"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
            <el-button
              type="warning"
              @click="showAdd"
              v-if="!_isMobile && $store.getters.role === 'admin'"
            >服务器掉线验证</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table" v-if="!_isMobile">
      <el-table size="mini" :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="serverport" label="端口" align="center" width="80px"></el-table-column>
        <el-table-column prop="bindingDevice" label="设备信息" align="center" width="150"></el-table-column>
        <el-table-column prop="bindingLocalIp" label="本地IP" align="center"></el-table-column>
        <el-table-column prop="bindingIp" label="公网IP" align="center"></el-table-column>
        <el-table-column prop="bindingImei" label="IMEI" align="center"></el-table-column>
        <el-table-column prop="comment" label="归属地" align="center"></el-table-column>
        <el-table-column prop="bindingUser" label="备注" align="center"></el-table-column>
        <el-table-column label="状态" align="center" width="80px">
          <template slot-scope="scope">
            <el-switch
              :disabled="!scope.row.serverport"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              @change="updateStatus(scope.row)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="online" label="在线" align="center" width="80px">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.online === 0">离线</el-tag>
            <el-tag type="success" v-if="scope.row.online === 1">在线</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="300px" align="center">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button size="mini" type="warning" @click="binding(scope.row)">设置端口</el-button>
              <el-button size="mini" type="warning" @click="switchIp(scope.row)">切换IP</el-button>
              <el-button size="mini" type="warning" @click="beizhu(scope.row)">备注</el-button>
              <el-button size="mini" type="warning" @click="deleteHandle(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-main v-else class="table-phone">
      <!-- 手机端查看 -->
      <div v-for="(item, i) in tableData" :key="i" class="table-item">
        <div class="online">
          <el-tag type="info" v-if="item.online === 0">离线</el-tag>
          <el-tag type="success" v-if="item.online === 1">在线</el-tag>
        </div>
        <div class="itembtn">
          <el-button size="mini" type="warning" @click="binding(item)">设置端口</el-button>
          <el-button size="mini" type="warning" @click="switchIp(item)">切换IP</el-button>
        </div>
        <div>端口：{{item.serverport}}</div>
        <div>本地IP：{{item.bindingLocalIp}}</div>
        <div>公网IP：{{item.bindingIp}}</div>
        <div>设备信息：{{item.bindingDevice}}</div>
        <div>归宿地：{{item.comment}}</div>
        <div>
          状态：
          <el-switch
            :disabled="!item.serverport"
            v-model="item.status"
            :active-value="1"
            :inactive-value="0"
            @change="updateStatus(item)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </div>
      </div>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      title="服务器掉线验证"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form class="body">
        <el-form-item>
          <!-- <el-input class="input" clearable v-model="form.cookies" placeholder="请输入cookies">
            <div slot="prepend" style="width: 80px;">Cookies</div>
          </el-input> -->
          <el-input class="input" clearable v-model="form.vcode" placeholder="请输入验证码">
            <div slot="prepend" style="width: 80px;">验证码</div>
            <div slot="append" style="width: 80px;">
              <img :src="vcodeurl" @click="refreshVcode" />
            </div>
          </el-input>
        </el-form-item>
        <el-button class="btn primaryBtn" type="warning" @click="loginHandle">验证</el-button>
        <!-- <el-button class="btn primaryBtn" type="warning" @click="saveCookes">保存</el-button> -->
        <div class="btns"></div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  selectPageSocks5,
  updatePort,
  startOrStop,
  setSetting,
  getSetting,
  switchIp,
  updateBeizhu,
  deleteSocks5,
  getVcode,
  vcodeLogin
} from '@/api'
import moment from 'moment'
export default {
  data() {
    return {
      vcodeurl: '',
      search: {
        current: 1,
        size: 20,
        total: 0,
        serverport: ''
      },
      form: {
        cookies: ''
      },
      buyUrl: '',
      status: {
        1: '已绑定',
        0: '未绑定',
        '-1': '小黑屋'
      },
      options: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '未绑定',
          value: '0'
        },
        {
          label: '已绑定',
          value: '1'
        }
      ],
      dialogVisible: false,
      tableData: []
    }
  },
  computed: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  },
  methods: {
    refreshVcode() {
        getVcode().then(res => {
          if (res.code === 20000) {
           this.vcodeurl =  'data:image/jpg;base64,' + res.data
          }
        })
        // this.vcodeurl = URL.createObjectURL(blob)
    },
    getCookies() {
      getSetting({ code: 'cookies' }).then((res) => {
        console.log('getSetting', res)
        this.form.cookies = res.data
      })
    },
    loginHandle() {
      vcodeLogin({
        code: this.form.vcode
      }).then((res) => {
        console.log('vcodeLogin', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '验证成功'
          })
          this.dialogVisible = false
        }
      })
    },
    saveCookes() {
      setSetting({
        code: 'cookies',
        value: this.form.cookies
      }).then((res) => {
        console.log('setSetting', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          this.dialogVisible = false
        }
      })
    },
    showAdd() {
      this.refreshVcode()
      this.dialogVisible = true
    },
    switchIp(row) {
      this.$confirm(`确定对该设备切换IP, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        switchIp({
          code: row.maccode
        }).then((res) => {
          console.log('switchIp', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功, 1分钟内终端切换飞行模式'
            })
          }
        })
      })
    },
    deleteHandle(row) {
      this.$confirm(`确定删除该设备吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSocks5({
          id: row.id
        }).then((res) => {
          console.log('deleteSocks5', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    binding(row) {
      this.$prompt('请输入绑定端口(4位数字)', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\d{4}/,
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        updatePort({
          id: row.id,
          port: value
        }).then((res) => {
          console.log('binding', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    beizhu(row) {
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        updateBeizhu({
          id: row.id,
          bindingUser: value
        }).then((res) => {
          console.log('updateBeizhu', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    loadData() {
      selectPageSocks5(this.search)
        .then((res) => {
          console.log('selectPageSocks5:', res)
          let now = moment()
          this.tableData = res.data.records.map((d) => {
            if (d.expireTime) {
              //获取需要对比的时间
              let m2 = moment(d.expireTime)
              if (m2 > now) {
                //计算相差多少天 day可以是second minute
                d.diffday = m2.diff(now, 'day')
              } else {
                d.diffday = 0
              }
            } else {
              d.diffday = '/'
            }
            return d
          })
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    handleCurrentPage(page) {
      this.checkCurrent = page
    },
    updateStatus(row) {
      startOrStop({
        id: row.id,
        status: row.status
      }).then((res) => {
        console.log('startOrStop', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.loadData()
        }
      })
    }
  },
  mounted() {
    this.getCookies()
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.body {
  ::v-deep {
    .el-input__inner {
      height: 60px;
    }
  }
}
.input2 {
  width: 60%;
}
.btn2 {
  height: 60px;
  width: calc(40% - 10px);
}
.btn {
  width: 100%;
  height: 60px;
}
.right {
  font-size: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.table-phone {
  padding: 0px;
  padding: 8px;
}
.table-item {
  position: relative;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e3e8ee;
  text-align: left;
  .online {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .itembtn {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>
