<template>
  <div class="work">
    <div class="center">
      <h1>欢迎使用</h1>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      date: new Date()
    }
  },
  methods: {
    gotopage (page) {
      this.$router.push({path: page})
    }
  }
}
</script>
<style lang="scss" scoped>
.work {
  width: 80%;
  height: calc(100vh - 300px);
  margin-left: 5%;
  margin-top: 20px;
  background-color: #fff;
  box-sizing: inherit;
  padding: 50px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
