  <!-- key -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent  :inline="true">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.searchkey"
                placeholder="IP、代理服务名称、游戏ID"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="showAdd" plain>添加代理IP</el-button>
            </el-form-item>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table size="mini" :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="ip" label="IP" align="center" width="150"></el-table-column>
        <el-table-column prop="name" label="代理服务名称" align="center"></el-table-column>
        <el-table-column prop="bindingId" label="绑定游戏ID" align="center" width="250"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" width="100"></el-table-column>
        <el-table-column prop="status" fixed="right" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status === 0">未绑定</el-tag>
            <el-tag type="info" v-if="scope.row.status === 1">已绑定</el-tag>
            <el-tag type="warning" v-if="scope.row.status === -1">小黑屋</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="250px" align="center">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button
                size="mini"
                type="warning"
                v-if="scope.row.status !== -1"
                @click="updateStatus(scope.row, true)"
              >小黑屋</el-button>
              <el-button
                size="mini"
                type="warning"
                v-if="scope.row.status === -1"
                @click="updateStatus(scope.row, false)"
              >释放</el-button>
              <el-button
                size="mini"
                type="warning"
                v-if="!scope.row.bindingId"
                @click="binding(scope.row)"
              >绑定</el-button>
              <el-button size="mini" type="warning" v-else @click="unbinding(scope.row)">解绑</el-button>
              <el-button size="mini" type="warning" @click="deleteHandle(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
     <el-dialog
      title="添加代理IP"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form class="body">
         <el-form-item>
          <el-input class="input"  clearable v-model="form.ip" placeholder="请输IP">
            <div slot="prepend" style="width: 80px;">IP</div>
            <div slot="append" @click="testProxy(form.ip)">测试</div>
          </el-input>
        </el-form-item>
         <el-form-item>
          <el-input class="input"  clearable v-model="form.name" placeholder="请输代理服务名称">
            <div slot="prepend" style="width: 80px;">代理服务名称</div>
          </el-input>
        </el-form-item>
        <el-button
          class="btn primaryBtn"
          type="warning"
          @click="saveHandle"
        >保存</el-button>
        <div class="btns"></div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  SelectPageProxyIPInfo,
  addProxyIPInfo,
  updateProxyIPInfo,
  deleteProxyIPInfo,
  testProxy
} from '@/api'
import {loadUserNumber} from '@/router'
import moment from 'moment'
export default {
  data() {
    return {
      search: {
        current: 1,
        size: 20,
        total: 0,
        searchkey: ''
      },
      form: {
        ip: '',
        name: ''
      },
      buyUrl: '',
      status: {
        1: '已绑定',
        0: '未绑定',
        '-1': '小黑屋'
      },
      options: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '未绑定',
          value: '0'
        },
        {
          label: '已绑定',
          value: '1'
        }
      ],
      dialogVisible: false,
      tableData: []
    }
  },
  methods: {
    showAdd () {
      this.dialogVisible = true
    },
    saveHandle () {
      let IPGZ = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
      if(!IPGZ.test(this.form.ip)) {
          this.$message({
            type: 'warning',
            message: 'ip地址格式有误'
          })
        return
      }
      let params = Object.assign({}, this.form)
      addProxyIPInfo(params).then((res) => {
        console.log('addProxyIPInfo', res)
        if (res.code === 20000) {
          this.$message({
            type: 'success',
            message: '添加成功'
          })
          this.dialogVisible = false
          this.loadData()
        }
      })
    },
    binding(row) {
      this.$prompt('请输入游戏ID', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        updateProxyIPInfo({
           id: row.id,
           bindingId: value,
           status: 1
        }).then((res) => {
          console.log('binding', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    deleteHandle(row) {
      this.$confirm(`删除此条代理, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteProxyIPInfo({
          id: row.id
        }).then((res) => {
          console.log('deleteProxyIPInfo', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
     unbinding(row) {
      this.$confirm(`确定解除${row.bindingId}使用吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
         updateProxyIPInfo({
           id: row.id,
           bindingId: '',
           status: 0
         }).then((res) => {
          console.log('unbinding', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    loadData() {
      loadUserNumber()
      SelectPageProxyIPInfo(this.search)
        .then((res) => {
          console.log('success:', res)
          let now = moment();
          this.tableData = res.data.records.map(d => {
            if (d.expireTime) {
              //获取需要对比的时间
              let m2 = moment(d.expireTime);
              if (m2 > now) {
                //计算相差多少天 day可以是second minute
                d.diffday = m2.diff(now, 'day')
              } else {
                d.diffday = 0
              }
            } else {
              d.diffday = '/'
            }
            return d
          })
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    handleCurrentPage(page) {
      this.checkCurrent = page
    },
    testProxy (ip) {
      testProxy({
        localIp: ip
      }).then(res => {
        console.log('testProxy', res);
        if (res.code === 20000) {
          let data = JSON.parse(res.data)
          if (data && ip == data.ip) {
             this.$message({
              type: 'success',
              message: `代理IP:${ip}连接正常`
            })
          } else {
             this.$message({
              type: 'warning',
              message: `代理IP:${ip}连接异常`
            })
          }
        }
      })
    },
    updateStatus(row, flag) {
      if (flag) {
        row.status = -1
      } else {
        if (row.bindingId) {
          row.status = 1
        } else {
          row.status = 0
        }
      }
       updateProxyIPInfo({
           id: row.id,
           bindingId: row.bindingId,
           status: row.status
         }).then((res) => {
        console.log('updateStatus', res)
        if (res.code === 20000) {
          this.loadData()
        }
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.body {
  ::v-deep {
    .el-input__inner {
      height: 60px;
    }
  }
}
.input2 {
  width: 60%;
}
.btn2 {
  height: 60px;
  width: calc(40% - 10px);
}
.btn {
  width: 100%;
  height: 60px;
}
.right {
  font-size: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
