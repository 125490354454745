  <!-- 日志管理 -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.bindingId"
                placeholder="游戏ID"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label class="nja-form-line">
              <el-select v-model="search.api">
                <el-option :label="t" :value="i" v-for="(t, i) in types" :key="i"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%" height="520px">
        <el-table-column prop="requestTime" label="时间" align="center" width="180"></el-table-column>
        <el-table-column prop="bindingId" label="游戏ID" align="center" width="180"></el-table-column>
        <el-table-column prop="result" label="成功次数" align="center" width="120"></el-table-column>
        <el-table-column prop="api" label="类型" align="center" width="120">
          <template slot-scope="scope">{{types[scope.row.api]}}</template>
        </el-table-column>
        <el-table-column prop="size" label="请求次数" align="center" width="120"></el-table-column>
        <el-table-column prop="useIp" label="使用IP" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="proxy" label="使用代理" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
  </div>
</template>

<script>
import { BuySelectPage } from '@/api'
export default {
  data() {
    return {
      dialogBatchVisible: false,
      dialogBuyVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        bindingId: '',
        api: ''
      },
      types: {
        '/api/kingdom/caravan/buy': '商人',
        '/api/kingdom/vipshop/buy': 'VIP商店',
        '/api/field/march/return': '多倍资源和水晶',
        '/api/skill/use': 'buff',
        '/api/kingdom/treasure/skill/upgrade': '装备'
      },
      form: {},
      buyUrl: '',
      tableData: []
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    loadData() {
      BuySelectPage(this.search)
        .then((res) => {
          console.log('success:', res)
          this.tableData = res.data.records
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
</style>
